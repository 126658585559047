import { Link } from 'gatsby';
import React from 'react';

const LinkHandler = ({ children, link, className }) => {
    if (link.type === 'internal') {
        return (
            <Link
                className={className}
                to={link.type === 'internal' ? link.page : link.url}
            >
                {children}
            </Link>
        );
    } else {
        return (
            <a
                href={link.url}
                className={className}
                rel="noopener noreferrer"
                target={link.target ? '_blank' : '_self'}
            >
                {children}
            </a>
        );
    }
};

export default LinkHandler;
