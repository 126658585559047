import { graphql } from 'gatsby';
import React, { useRef, useState } from 'react';
import {
    NotificationContainer,
    NotificationManager,
} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import styled from 'styled-components';
import ArrowButton from '../components/common/ArrowButton';
import DoubleColumnText from '../components/common/DoubleColumnText';
import Header from '../components/common/Header';
import OfficeList from '../components/common/OfficeList';
import LayoutCt from '../components/layout';
import SEO from '../components/SEO/SEO';
import colors from '../styles/colors';

const Layout = styled(LayoutCt)`
    .intro {
        z-index: 3;
    }
    .notification-container{
        top: 10px;
        right: 10px;
        width: 365px;
        .notification-success{
            background: linear-gradient(136deg, ${colors.pink} 19%, #293f75 122%);
            .notification-message{
                .title {
                    font-family: 'Heebo';
                    font-size: 1.8rem;
                    margin: 0;
                }
                .message{
                    display: none;
                }
            }
        }
    }
    .container-form {
        z-index: 2;
        grid-column: 2/3;
        margin-top: 40px;
        .input-container {
            width: 100%;
            margin-bottom: 30px;
            .label {
                font-weight: 300;
                color: ${colors.lightgrey};
            }
            textarea {
                &.input {
                    border: 1px solid ${colors.lightgrey};
                    margin-top: 10px;
                    padding: 20px;
                    height: 360px;
                }
            }
            .input {
                background: none;
                border: none;
                border-bottom: 1px solid ${colors.lightgrey};
                color: white;
                width: 100%;
                padding: 20px 5px;
            }
        }
        .bottom-form {
            .text-1 {
                font-weight: 300;
            }
            .container-btn-send {
                color: white;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 20px;
                span {
                    letter-spacing: 4px;
                    text-transform: uppercase;
                    font-weight: normal;
                    margin-right: 10px;
                }
                .btn-send {
                    background: none;
                    border: none;
                    &.disable{
                        pointer-events: none;
                        .btn-arrow{
                            background: grey;
                        }
                    }
                    .btn-arrow {
                        position: relative;
                        svg {
                            position: absolute;
                        }
                    }
                }
            }
        }
    }
    }
    
    @media only screen and (min-width: 800px){
    .container-form{
        grid-column: 4/12;
        margin-top: 140px;
        .top-form{
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            .input-container{
                width: 47%;
            }
        }
        .title-2{
            font-size: 3.5rem;
            line-height: 3.5rem;
        }
        .bottom-form{
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            .container-btn-send{
                margin-top: 0;
            }
        }
    }
`;

export const query = graphql`
    query($lang: WpLanguageCodeEnum) {
        allWpPage(
            filter: {
                language: { code: { eq: $lang } }
                template: { templateName: { eq: "Contact" } }
            }
        ) {
            edges {
                node {
                    seo {
                        description: metaDesc
                        title
                    }
                    acfPageContact {
                        email
                        header {
                            subtitle
                            title
                            type
                        }
                        offices {
                            address
                            email
                            name
                            phone
                        }
                        double_column_text {
                            images {
                                image_desktop {
                                    filesize
                                    height
                                    mime_type
                                    title
                                    url
                                    width
                                }
                                image_mobile {
                                    filesize
                                    height
                                    mime_type
                                    title
                                    url
                                    width
                                }
                            }
                            link {
                                label
                                page
                                target
                                type
                                url
                            }
                            label
                            position_image
                            text_left
                            text_right
                            title
                        }
                    }
                }
            }
        }
        wp {
            sp {
                strings {
                    contact_form_label_send {
                        en
                        fr
                    }
                    contact_office_label {
                        en
                        fr
                    }
                    contact_form_caption {
                        en
                        fr
                    }
                    contact_form_label_email {
                        en
                        fr
                    }
                    contact_form_label_message {
                        en
                        fr
                    }
                    contact_form_label_name {
                        en
                        fr
                    }
                    contact_form_placeholder_email {
                        en
                        fr
                    }
                    contact_form_placeholder_message {
                        en
                        fr
                    }
                    contact_form_placeholder_name {
                        en
                        fr
                    }
                }
            }
        }
    }
`;

const Contact = ({ data, pageContext }) => {
    const seo = data.allWpPage.edges[0].node.seo;
    let strings = data.wp.sp.strings;

    data = data.allWpPage.edges[0].node.acfPageContact;

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const button = useRef(null);

    const createNotification = (type) => {
        return () => {
            if (type !== 'success') return;
            NotificationManager.success(
                '',
                pageContext.lang === 'FR'
                    ? 'Votre message a bien été envoyé'
                    : 'Your message have been send',
                10000,
            );
        };
    };

    const successForm = createNotification('success');

    const success = () => {
        button.current.classList.add('disable');
        successForm();
        setName('');
        setEmail('');
        setMessage('');
        setTimeout(() => {
            button.current.classList.remove('disable');
        }, 10000);
    };

    const sendForm = (e) => {
        e.preventDefault();

        let data = new FormData();

        data.append('name', name);
        data.append('email', email);
        data.append('message', message);

        let request = new XMLHttpRequest();
        request.open(
            'POST',
            `${process.env.GATSBY_WP_URL}/wp-json/form/contact`,
            true,
        );

        request.onreadystatechange = function () {
            let parse = JSON.parse(request.response);
            let status = parse.status;
            if (
                request.readyState === XMLHttpRequest.DONE &&
                request.status === 200 &&
                status === 'ok'
            ) {
                success();
            }
        };
        request.send(data);
    };

    return (
        <Layout
            lang={pageContext.lang}
            pathTranslatePage={pageContext.pathTranslatePage}
        >
            <SEO
                lang={seo.lang}
                title={seo.title}
                description={seo.description}
                translations={pageContext.translations}
            />
            <Header data={data.header} />
            <DoubleColumnText data={data.double_column_text} />
            <form
                className="container-form"
                id="form"
                method="post"
                onSubmit={sendForm}
            >
                <div className="top-form">
                    <div className="input-container">
                        <label htmlFor="name" className="label text-1">
                            {pageContext.lang === 'FR'
                                ? strings.contact_form_label_name.fr
                                : strings.contact_form_label_name.en}
                        </label>
                        <input
                            type="text"
                            name="name"
                            id="name"
                            placeholder={
                                pageContext.lang === 'FR'
                                    ? strings.contact_form_placeholder_name.fr
                                    : strings.contact_form_placeholder_name.en
                            }
                            className="input title-2"
                            required
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </div>
                    <div className="input-container">
                        <label htmlFor="email" className="label text-1">
                            {pageContext.lang === 'FR'
                                ? strings.contact_form_label_email.fr
                                : strings.contact_form_label_email.en}
                        </label>
                        <input
                            type="email"
                            name="email"
                            id="email"
                            placeholder={
                                pageContext.lang === 'FR'
                                    ? strings.contact_form_placeholder_email.fr
                                    : strings.contact_form_placeholder_email.en
                            }
                            className="input title-2"
                            required
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                </div>
                <div>
                    <div className="input-container">
                        <label htmlFor="message" className="label text-1">
                            {pageContext.lang === 'FR'
                                ? strings.contact_form_label_message.fr
                                : strings.contact_form_label_message.en}
                        </label>
                        <textarea
                            name="message"
                            id="message"
                            placeholder={
                                pageContext.lang === 'FR'
                                    ? strings.contact_form_placeholder_message
                                          .fr
                                    : strings.contact_form_placeholder_message
                                          .en
                            }
                            className="input title-2"
                            required
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                        ></textarea>
                    </div>
                </div>
                <div className="bottom-form">
                    <span className="text-1">
                        {pageContext.lang === 'FR'
                            ? strings.contact_form_caption.fr
                            : strings.contact_form_caption.en}
                        <a href={'mailto:' + data.email}>{data.email}</a>
                    </span>
                    <div className="container-btn-send">
                        <span className="text-1">
                            {pageContext.lang === 'FR'
                                ? strings.contact_form_label_send.fr
                                : strings.contact_form_label_send.en}
                        </span>
                        <button type="submit" className="btn-send" ref={button}>
                            <ArrowButton
                                type="gradient"
                                className="btn-arrow"
                            />
                        </button>
                    </div>
                </div>
            </form>
            <OfficeList
                data={data.offices}
                labelOffices={
                    pageContext.lang === 'FR'
                        ? strings.contact_office_label.fr
                        : strings.contact_office_label.en
                }
            />
            <NotificationContainer />
        </Layout>
    );
};

export default Contact;
