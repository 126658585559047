import React from 'react';
import { useInView } from "react-intersection-observer";
import styled from 'styled-components';
import ImageLeft from './ImageLeft';
import SimpleButton from './SimpleButton';

const Wrapper = styled.div`
    color: white;
    grid-column: 2/3;
    z-index: 2;
    &:not(.anim){
        .title, .title-label , .firstSection, .secondSection{
            transform: translateY(50px);
            opacity: 0;
        }
        hr{
            width: 0;
        }
    }
    .title, .title-label{
        transition: opacity 800ms, transform 500ms;
    }
    h2 {
        margin-top: 30px;
    }
    hr {
        height: 2px;
        width: 30px;
        border: none;
        background: white;
        margin: 20px 0;
        transition: width 400ms;
    }
    .firstSection, .secondSection{
        transition: opacity 800ms, transform 500ms;
    }
    .firstSection {
        font-family: 'Unna';
        font-size: 2rem;
        line-height: 2.8rem;
    }
    .secondSection {
        margin-top: 20px;
        font-family: 'Heebo';
        font-weight: 300;
        font-size: 1.4rem;
        line-height: 2.4rem;
    }
    .button {
        margin-top: 40px;
        grid-column: 4/6;
        margin-bottom: 20px;
    }
    @media screen and (min-width: 800px) {
        grid-column: ${(props) => (props.imageLeft ? '3/13' : '4/12')};
        display: grid;
        grid-template-columns: ${(props) =>
        props.imageLeft ? 'repeat(10, 1fr)' : 'repeat(8, 1fr)'};
        .rightContainer {
            grid-column: 5/10;
        }
        .title-label,
        .title-2 {
            grid-column: 1/7;
        }
        hr {
            grid-column: 1/9;
        }
        h2{
            margin-top: 20px;
        }
        .sections {
            grid-column: 1/9;
            display: ${(props) => (props.imageLeft ? 'block' : 'grid')};
            grid-template-columns: repeat(8, 1fr);
            .firstSection {
                grid-column: 1/5;
                font-size: 2.5rem;
                line-height: 1.28;
            }
            .secondSection {
                margin-left: 50px;
                grid-column: 5/9;
                margin-top: 0;
            }
            .small {
                font-family: 'Heebo';
                font-weight: 300;
                font-size: 1.4rem;
                line-height: 1.7;
            }
        }
    }
`;

const DoubleColumnText = ({ data, className, id }) => {

    const { ref, inView } = useInView({
        /* Optional options */
        threshold: 0,
        triggerOnce: true
    });

    const buildContent = () => (
        <>
            {data.label && <h4 className="title-label">{data.label}</h4>}
            {(data.title || data.text) && (
                <h2
                    className="title-2 title"
                    dangerouslySetInnerHTML={{
                        __html: data.title || data.text,
                    }}
                ></h2>
            )}
            <hr />
            <div className="sections">
                {!data.text_left && data.text_right && (
                    <p
                        className="firstSection small"
                        dangerouslySetInnerHTML={{ __html: data.text_right }}
                    ></p>
                )}
                {data.text_left && (
                    <p
                        className="firstSection"
                        dangerouslySetInnerHTML={{ __html: data.text_left }}
                    ></p>
                )}
                {data.text_right && data.text_left && (
                    <p
                        className="secondSection"
                        dangerouslySetInnerHTML={{ __html: data.text_right }}
                    ></p>
                )}
            </div>
            {data.link.label && (
                <SimpleButton className="button" data={data.link} />
            )}
        </>
    );

    return (
        <Wrapper
            imageLeft={data.images.image_desktop.url}
            className={`${className} ct ${inView ? "anim" : ""}`}
            id={id}
            ref={ref}
        >
            {data.images.image_desktop.url ? (
                <>
                    <ImageLeft images={data.images} />
                    <div className="rightContainer">{buildContent()}</div>
                </>
            ) : (
                    buildContent()
                )}
        </Wrapper>
    );
};

export default DoubleColumnText;
