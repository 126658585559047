import React from 'react';
import styled from 'styled-components';
import colors from '../../styles/colors';
import ArrowRight from '../../svg/arrow-right.inline.svg';

const Wrapper = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background: ${(props) =>
        props.backgroundStyle === 'gradient' &&
        'linear-gradient(136deg, ' + colors.pink + ' 19%, #293f75 122%)'};
    border: ${(props) =>
        props.backgroundStyle === 'light' && 'solid 1px white'};
    svg {
        left: 50%;
        top: 50%;
        position: relative;
        transform: translate(-50%, -50%);
    }
    @media screen and (min-width: 800px) {
        transition: all 250ms ease-in-out;
        cursor: pointer;
        &:hover {
            transform: scale(1.15);
        }
    }
`;

const ArrowButton = ({ className, type }) => (
    <Wrapper backgroundStyle={type} className={className}>
        <ArrowRight />
    </Wrapper>
);

export default ArrowButton;
