import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    grid-column: 2/3;
    position: relative;
    margin-top: 40px;

    .title {
        position: relative;
        display: inline-block;
        padding-right: 130px;
        &:before {
            display: block;
            content: '';
            width: 80px;
            height: 1px;
            background-color: white;
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
        }
    }
    .container-offices {
        margin-top: 30px;
        .office {
            width: 100%;
            font-weight: 300;
            display: flex;
            flex-direction: column;
            margin-bottom: 20px;
            .name {
                font-weight: bold;
            }
        }
    }

    @media screen and (min-width: 800px) {
        grid-column: 4/12;
        margin-top: 120px;

        .container-offices {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            flex-wrap: wrap;

            &.one-office {
                display: inline-block;
                margin-left: 50px;
                .office {
                    width: 100%;
                }
            }
            .text-1 {
                font-size: 1.8rem;
                line-height: 2.8rem;
            }
            .office {
                width: calc(100% / 3);
                padding-right: 60px;
                span {
                    display: inline-block;
                }
            }
        }
    }
`;


const OfficeList = ({ className, data, labelOffices }) => {
    return (
        <Wrapper data={data} className={className}>
            <h3 className="title-2 title">{labelOffices}</h3>
            <div
                className={
                    data.length === 1
                        ? 'one-office container-offices'
                        : 'container-offices'
                }
            >
                {data.map((item, index) => (
                    <div className="office text-1">
                        <span className="name">{item.name}</span>
                        <span>{item.address}</span>
                        <a href={'mailto:' + item.email}>{item.email}</a>
                        <a href={'tel:' + item.phone}>{item.phone}</a>
                    </div>
                ))}
            </div>
        </Wrapper>
    );
};

export default OfficeList;
