import React from 'react';
import { useInView } from 'react-intersection-observer';
import { Parallax } from 'react-scroll-parallax';
import styled from 'styled-components';
import { absolutify } from '../../utils/absolutify';

const Wrapper = styled.div`
    grid-column: 2/3;
    .image {
        img {
            width: 100%;
            margin-bottom: 50px;
            transition: all 500ms ease-in-out;
        }
    }
    @media screen and (min-width: 800px) {
        grid-column: 1/4;
        img {
            height: 100%;
            object-fit: cover;
        }
    }
`;

export default function ImageLeft({ className, images }) {
    const { ref } = useInView({
        /* Optional options */
        threshold: 0,
        triggerOnce: true,
    });

    return (
        <Wrapper className={`${className}`} ref={ref}>
            <Parallax y={[-15, 15]}>
                <picture className="image">
                    <source
                        media="(min-width: 800px)"
                        srcSet={absolutify(images.image_desktop.url)}
                    />
                    <img
                        alt={images.image_desktop.title}
                        src={absolutify(images.image_mobile.url)}
                    />
                </picture>
            </Parallax>
        </Wrapper>
    );
}
