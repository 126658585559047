import React from 'react';
import styled from 'styled-components';
import LinkHandler from './LinkHandler';
import {useInView} from "react-intersection-observer";

const Wrapper = styled.div`
    color: white;
    text-transform: uppercase;
    font-family: 'Heebo';
    text-decoration: none;
    font-size: 1.2rem;
    letter-spacing: 0.25rem;
    text-align: center;
    padding: 15px 0;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    
    &:not(.anim){
        .circle{
            opacity: 0;
        }
        .text-anim{
            transform: translateY(50px);
            opacity: 0;
        }
    }
    .text-anim{
        transition: all 600ms ease-in-out 800ms;
    }
    .link{
      position: relative;
      .circle {
        position: absolute;
        width: 40px;
        height: 40px;
        border-radius: 100%;
        left: 50%;
        transform: translate(-50%, -37%);
        border: 1px solid #ffaebf;
        mask-image: linear-gradient(black 0%, transparent 50%, black 100%);
        mask-repeat: no-repeat;
        transition: opacity 500ms ease-in-out;
      }
    }
    @media screen and (min-width: 800px) {
        font-size: 1.4rem;
        letter-spacing: 0.4rem;
        padding: 25px 0;
        
        .link{
            &:hover{
                .circle{
                  transform: translate(-50%, -40%) scale(1.3);
                }
                .label {
                  letter-spacing: 0.6rem;
                } 
            } 
            .label {
                transition: all 250ms ease-in-out;
                cursor: pointer;
            }
            .circle {
                transition: all 250ms ease-in-out;
                width: 60px;
                height: 60px;
                transform: translate(-50%, -40%);
                box-shadow: 0 0 1px 0px white inset, 0 0 1px 0px white; // anti aliasing
            }
        }
    }
`;

const SimpleButton = ({ className, data }) => {
    const { ref, inView } = useInView({
        /* Optional options */
        threshold: 0,
        triggerOnce: true,
        delay: 800
    });
    return (
        <Wrapper className={`${className} ${inView ? "anim" : ""}`} ref={ref}>
            <LinkHandler link={data} className="link">
                <div className="circle"></div>
                <p className="label text-anim">{data.label}</p>
            </LinkHandler>
        </Wrapper>
    )
};

export default SimpleButton;
